import Layout from '@/layout/index.vue';
import template from '@/template/index.vue';

const applicationRouter = {
  path: '',
  component: Layout,
  // component: () => import('@/views/operation/application/applicationList/index.vue'),
  meta: { title: '外部应用管理' },
  children: [
    {
      path: '/application/list',
      name: 'ApplicationList',
      component: () => import('@/views/application/index.vue'),
      meta: {
        title: '应用列表',
      },
    },
    {
      path: '/application/supplier',
      component: () => import('@/views/application/supplierList/index.vue'),
      meta: {
        title: '商家管理',
      },
    },
    {
      path: '/application/recommend',
      component: () => import('@/views/application/recommendList/index.vue'),
      meta: {
        title: '推荐位管理',
      },
    },
    {
      path: '/application/classify/index',
      component: () => import('@/views/operation/application/classify/index.vue'),
      meta: {
        title: '分类商品管理',
      },
    },
    {
      path: '/application/goods/list',
      component: () => import('@/views/operation/application/goodsList/index.vue'),
      meta: {
        title: '商品管理',
      },
    },
    {
      path: '/application/content/list',
      component: () => import('@/views/operation/application/content/contentList.vue'),
      meta: {
        title: '内容管理',
      },
    },
    // 代翔添加
    {
      path: '/application/offline',
      component: () => import('@/views/operation/application/navigation/offline.vue'),
      children: [

        {
          path: 'signUp', name: 'signUp', meta: { title: '报名列表' }, component: template,
        },
        {
          path: 'contentList', name: 'contentList', meta: { title: '内容管理' }, component: template,
        },
        {
          path: 'commentList', name: 'commentList', meta: { title: '评论管理' }, component: template,
        },
        {
          path: 'signUpAdd',
          name: 'signUpAdd',
          component: () => import('@/pages/signUpAdd.vue'),
          meta: { title: '编辑/创建报名' },
        },
        {
          path: 'contentAdd',
          name: 'contentAdd',
          component: () => import('@/pages/contentAdd.vue'),
          meta: { title: '编辑/新增内容' },
        },
        {
          path: 'rankingList', name: 'rankingList', meta: { title: '消费之星排行榜' }, component: template,
        },
        {
          path: 'userVoucher', name: 'userVoucher', meta: { title: '用户券查询' }, component: template,
        },
        {
          path: 'warningAudit',
          name: 'warningAudit',
          meta: { title: '商家预警审核' },
          component: () => import('@/pages/warningAudit.vue'),
        },
      ],
    },

    // 应用设置
    {
      path: '/application/appSettings',
      name: 'appSettings',
      component: () => import('@/views/application/appSettings/index.vue'),
      redirect: 'noredirect',
      meta: {
        title: '应用设置',
      },
      children: [
        {
          path: '',
          redirect: '/application/appSettings/theme',
        },
        {
          path: '/application/dataAnalysis',
          name: 'DataAnalysis',
          component: () => import('@/views/application/dataAnalysis/index.vue'),
          meta: {
            title: '数据分析',
          },
        },
        {
          path: '/nb/map/list',
          component: () => import('@/views/nb/miniProgram/map/list.vue'),
          meta: {
            title: '地图管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: 'pageManage',
          component: () => import('@/views/application/appSettings/pageManage/index.vue'),
          meta: {
            title: '页面管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/application/mine/list',
          component: () => import('@/views/operation/application/mine/list.vue'),
          meta: {
            title: '个人中心管理',
          },
        },
        {
          path: '/nb/line/list',
          component: () => import('@/views/nb/miniProgram/line/list.vue'),
          meta: {
            title: '线路管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: 'theme',
          component: () => import('@/views/application/appSettings/theme/index.vue'),
          meta: {
            title: '色系管理',
          },
        },
        {
          path: '/templates/list',
          component: () => import('@/views/templates/list.vue'),
          meta: {
            title: '模板管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/application/guide',
          component: () => import('@/views/application/appSettings/guide/list.vue'),
          meta: {
            title: '导览管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/nb/service/list',
          component: () => import('@/views/nb/miniProgram/service/list.vue'),
          meta: {
            title: '服务点管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/nb/service/appointment/edit',
          component: () => import('@/views/nb/miniProgram/service/appointmentEdit.vue'),
          meta: {
            title: '设置预约管理',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/nb/service/appointment/list',
          component: () => import('@/views/nb/miniProgram/service/appointment.vue'),
          meta: {
            title: '预约记录',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/nanpuxi/chef',
          component: () => import('@/views/nanpuxi/chef/index.vue'),
          meta: {
            title: '乡村大厨',
          },
        },
        {
          path: '/nanpuxi/chef/detail',
          component: () => import('@/views/nanpuxi/chef/detail.vue'),
          meta: {
            title: '乡村大厨详情',
          },
        },
        {
          path: '/nanpuxi/score/goods',
          component: () => import('@/views/nanpuxi/score/goods/index.vue'),
          meta: {
            title: '积分商品管理',
          },
        },
        {
          path: '/nanpuxi/score/goods/detail',
          component: () => import('@/views/nanpuxi/score/goods/detail.vue'),
          meta: {
            title: '积分商品详情',
          },
        },
        {
          path: '/nanpuxi/score/rule',
          component: () => import('@/views/nanpuxi/score/rule/index.vue'),
          meta: {
            title: '积分规则',
          },
        },
        {
          path: '/nanpuxi/score/record',
          component: () => import('@/views/nanpuxi/score/record/index.vue'),
          meta: {
            title: '积分记录',
          },
        },
        {
          path: '/nanpuxi/shop',
          component: () => import('@/views/nanpuxi/shop/index.vue'),
          meta: {
            title: '店铺招募管理',
          },
        },
        {
          path: '/nanpuxi/home/dashboard',
          component: () => import('@/views/nanpuxi/home/dashboard.vue'),
          meta: {
            title: '首页',
          },
        },
        {
          path: 'navigation',
          component: () => import('@/views/application/appSettings/navigation/index.vue'),
          meta: {
            title: '导航管理',
          },
        },
        {
          path: '/application/phone/list',
          component: () => import('@/views/operation/application/phone/list.vue'),
          meta: {
            title: '便民电话管理',
          },
        },
        {
          path: '/appSettings/offline',
          name: 'offlineList',
          meta: { title: '活动列表' },
          component: template,
        },
        {
          path: '/application/notice/list',
          component: () => import('@/views/operation/application/noticeList/index.vue'),
          meta: {
            title: '公告管理',
          },
        },
        {
          path: '/application/message/list',
          component: () => import('@/views/operation/application/messageList/index.vue'),
          meta: {
            title: '留言管理',
          },
        },
        {
          path: '/application/messageList/index',
          component: () => import('@/views/ldx/message/list.vue'),
          meta: {
            title: '消息列表',
          },
        },
        {
          path: '/application/messageList/message/msgDetail',
          component: () => import('@/views/ldx/message/msgDetail.vue'),
          meta: {
            title: '运营主动推送详情',
          },
        },
        {
          path: '/application/messageList/message/msgDetail',
          name: 'appMsgDetail',
          component: () => import('@/views/ldx/message/msgDetail.vue'),
          meta: {
            title: '运营主动推送详情',
          },
        },
        {
          path: '/application/operation/intelligentList',
          component: () => import('@/views/operation/application/intelligent-list'),
          meta: {
            title: '智能问答（南浦溪小程序）',
            icon: 'iconcaiwurenminbizhifux',
          },
        },
        {
          path: '/application/operation/supplier',
          component: () => import('@/views/application/supplierList/index.vue'),
          meta: {
            title: '商家管理',
          },
        },
        {
          path: '/application/operation/recommend',
          component: () => import('@/views/application/recommendList/index.vue'),
          meta: {
            title: '推荐位管理',
          },
        },
        {
          path: '/application/operation/home',
          component: () => import('@/views/application/appSettings/home/index.vue'),
          meta: {
            title: '首页',
          },
        },
        {
          path: '/application/operation/contentList', name: 'contentList', meta: { title: '内容管理' }, component: template,
        },
        {
          path: '/application/operation/commentList', name: 'commentList', meta: { title: '评论管理' }, component: template,
        },
      ],
    },
    // 四明红旅-定制行程管理
    {
      path: '/application/trip',
      name: 'siTrip',
      component: () => import('@/views/ldx/trip/index.vue'),
      meta: {
        title: '定制行程管理',
      },
    },
    // 四明红旅-内容管理
    {
      path: '/application/contentManage',
      name: 'siContentManage',
      component: () => import('@/views/ldx/contentManage/index.vue'),
      meta: {
        title: '内容管理',
      },
    },
    // 四明红旅-内容设置
    {
      path: '/application/contentSet',
      name: 'contentSet',
      component: () => import('@/views/ldx/contentManage/contentSet.vue'),
      meta: {
        title: '内容设置',
      },
    },
    // 四明红旅-内容设置-新建
    {
      path: '/application/contentSet/add',
      name: 'contentSetAdd',
      component: () => import('@/views/ldx/contentManage/detail.vue'),
      meta: {
        title: '新建内容',
      },
    },
    {
      path: '/application/contentSet/edit',
      name: 'contentSetEdit',
      component: () => import('@/views/ldx/contentManage/detail.vue'),
      meta: {
        title: '编辑内容',
      },
    },
  ],
};

export default applicationRouter;
