import Layout from '@/layout/index.vue';

const subjectChannelRouter = {
  path: '',
  component: Layout,
  meta: { title: '频道页管理' },
  children: [
    {
      path: '/subjectChannel/pool',
      component: () => import('@/views/subjectChannel/pool/index.vue'),
      meta: {
        title: '选品池',
      },
    },
    {
      path: '/atmosphereYou/index',
      component: () => import('@/views/subjectChannel/atmosphereYou/index.vue'),
      meta: {
        title: '优选频道氛围管理',
      },
    },
    {
      path: '/subjectChannel/goodsGroup',
      component: () => import('@/views/subjectChannel/goodsGroup/index.vue'),
      meta: {
        title: '选品分组',
      },
    },
    {
      path: '/subjectChannel/goodsGroup/detail',
      component: () => import('@/views/subjectChannel/goodsGroup/detail.vue'),
      meta: {
        title: '选品分组详情',
      },
    },
    {
      path: '/subjectChannel/shopGroup',
      component: () => import('@/views/subjectChannel/shopGroup/index.vue'),
      meta: {
        title: '店铺分组分组',
      },
    },
    {
      path: '/subjectChannel/shopGroup/detail',
      component: () => import('@/views/subjectChannel/shopGroup/detail.vue'),
      meta: {
        title: '店铺分组分组详情',
      },
    },
    {
      path: '/subjectChannel/styleList',
      component: () => import('@/views/subjectChannel/styleList/index.vue'),
      meta: {
        title: '样式管理',
      },
    },
    {
      path: '/subjectChannel/styleList/detailGoods',
      component: () => import('@/views/subjectChannel/styleList/detailGoods.vue'),
      meta: {
        title: '商品样式详情',
      },
    },
    {
      path: '/subjectChannel/styleList/detailShop',
      component: () => import('@/views/subjectChannel/styleList/detailShop.vue'),
      meta: {
        title: '店铺样式详情',
      },
    },
    {
      path: '/subjectChannel/moduleEntrance',
      component: () => import('@/views/subjectChannel/moduleEntrance/index.vue'),
      meta: {
        title: '模块入口图配置',
      },
    },
    {
      path: '/subjectChannel/moduleEntranceDetail',
      component: () => import('@/views/subjectChannel/moduleEntrance/detail.vue'),
      meta: {
        title: '模块入口图',
      },
    },
    {
      path: '/subjectChannel/classificationManagement',
      component: () => import('@/views/subjectChannel/classificationManagement'),
      meta: {
        title: '分类管理',
      },
    },
    {
      path: '/subjectChannel/classificationManagementDetail',
      component: () => import('@/views/subjectChannel/classificationManagement/detail.vue'),
      meta: {
        title: '分类管理详情',
      },
    },
    {
      path: '/subjectChannel/categoryPage',
      component: () => import('@/views/subjectChannel/categoryPage'),
      meta: {
        title: '分类页面',
      },
    },
    {
      path: '/subjectChannel/categoryPageDetail',
      component: () => import('@/views/subjectChannel/categoryPage/detail.vue'),
      meta: {
        title: '分类页面详情',
      },
    },
  ],
};

export default subjectChannelRouter;
