const base = APPLICATION_ENV.GOODS_API;
// const base = 'http://10.10.10.86:8087/'

const goodsApis = {
  nb_common: {
    file_upload: `${base}file/upload`, // 文件上传
    newUpload: `${base}file/newUpload`, // 文件上传新接口
  },
  trade_template_mail: {
    list: `${base}backplat/trade-template-mail/list`, // 运费模板列表
    detail: `${base}backplat/trade-template-mail/detail`, // 运费模板详情
  },
  trade: {
    attribute_list: `${base}backplat/trade/attribute/list`, // 属性列表
    attribute_enable: `${base}backplat/trade/attribute/enable`, // 属性列表
    attribute_add: `${base}backplat/trade/attribute/add`, // 属性创建
    attribute_delete: `${base}backplat/trade/attribute/delete`, // 属性删除
    attribute_detail: `${base}backplat/trade/attribute/detail`, // 属性详情
    attribute_edit: `${base}backplat/trade/attribute/edit`, // 属性编辑
    attribute_group_list: `${base}backplat/trade/attribute/group/list`, // 属性组列表
    attribute_group_enable: `${base}backplat/trade/attribute/group/enable`, // 属性组启停用
    attribute_group_delete: `${base}backplat/trade/attribute/group/delete`, // 属性组删除
    attribute_group_add: `${base}backplat/trade/attribute/group/add`, // 属性组创建
    attribute_group_detail: `${base}backplat/trade/attribute/group/detail`, // 属性组详情
    attribute_group_edit: `${base}backplat/trade/attribute/group/edit`, // 属性组编辑
    attribute_group_export: `${base}backplat/trade/attribute/export`, // 属性列表导出
  },
  backendCategory: {
    backend_category: `${base}/backplat/backend_category`, // 后台三级类目
    backend_category_list: `${base}backplat/backend_category/list`, // 后台类目列表
    backend_category_ban: `${base}backplat/backend_category/ban`, // 禁用/激活后台类目
    backend_category_add: `${base}backplat/backend_category/add`, // 新增后台类目
    backend_category_edit: `${base}backplat/backend_category/edit`, // 编辑后台类目
    backend_category_detail: `${base}backplat/backend_category/detail`, // 后台类目详情
    backend_category_remove: `${base}backplat/backend_category/remove`, // 删除后台类目
    relation_page: `${base}backplat/backend_category/relation_page`, // 查询已关联列表（属性、规格、品牌、标签）
    update_sort: `${base}backplat/backend_category/update_sort`, // 后台类目更新排序
    batch_add: `${base}backplat/backend_category/batch_add`, // 后台类目批量新增关联
    batch_del: `${base}backplat/backend_category/batch_del`, // 后台类目批量移除关联
  },
  backplat: {
    trade_frontend_category_page: `${base}backplat/trade-frontend-category/page`, // 前台类目列表(分页)
    trade_frontend_category_ban: `${base}backplat/trade-frontend-category/ban`, // 上架/下架前台类目
    trade_frontend_category_list: `${base}backplat/trade-frontend-category/list`, // 前台类目列表
    trade_frontend_category_add: `${base}backplat/trade-frontend-category/add`, // 前台类目列表
    trade_frontend_category_supplychain_add: `${base}backplat/trade-frontend-category/supplychain/add`, // 农博优选新增前台类目
    trade_frontend_category_edit: `${base}backplat/trade-frontend-category/edit`, // 编辑前台类目
    trade_frontend_category_supplychain_edit: `${base}backplat/trade-frontend-category/supplychain/edit`, // 农博优选编辑前台类目
    trade_frontend_category_detail: `${base}backplat/trade-frontend-category/detail`, // 前台类目详情
    trade_frontend_category_remove: `${base}backplat/trade-frontend-category/remove`, // 删除前台类目
    trade_frontend_category_setSort: `${base}backplat/trade-frontend-category/setSort`, // 前台类目设置排序
    frontendCategoryTree: `${base}backplat/trade-frontend-category/frontendCategoryTree`, // 前台类目
    frontendCategoryTreeYx: `${base}backplat/trade-frontend-category/supplychain/frontendCategoryTree`, // 农博优选前台类目
    judgeGoodsValid: `${base}backplat/trade-frontend-category/judgeGoodsValid`, // 校验商品是否在指定类目中

  },
  goodsmanage: {
    trade_goods_page: `${base}backplat/goodsmanage/trade-goods/page`, // 查询商品列表(分页)
    trade_goods_modify: `${base}backplat/goodsmanage/trade-goods/modify`, // 商品上下架
    trade_goods_self_status: `${base}backplat/goodsmanage/trade-goods/self_status`, // 设置自提状态
    trade_goods_detail: `${base}backplat/goodsmanage/trade-goods/detail`, // 查看商品
    trade_goods_addSpec: `${base}backplat/goodsmanage/trade-goods/addSpec`, // 新增商品规格
    trade_goods_editSpec: `${base}backplat/goodsmanage/trade-goods/editSpec`, // 编辑商品规格
    trade_goods_add: `${base}backplat/goodsmanage/trade-goods/add`, // 新增商品
    trade_goods_edit: `${base}backplat/goodsmanage/trade-goods/edit`, // 编辑商品
    trade_goods_remove: `${base}backplat/goodsmanage/trade-goods/remove`, // 删除商品
    trade_goods_audit: `${base}backplat/goodsmanage/trade-goods/audit`, // 审核通过/拒绝
    trade_goods_detail_export: `${base}backplat/goodsmanage/trade-goods/detail/export`, // 商品明细导出
    listGoods: `${base}backplat/goodsmanage/trade-goods/listGoods`, // 商品列表-分页
    trade_goods_baseGuarantee: `${base}backplat/goodsmanage/trade-goods/goods/baseGuarantee`, // 获取基础保障
    selectGoodsForLive: `${base}backplat/goodsmanage/trade-goods/selectGoodsForLive`, // 获取直播可以添加的商品
    trade_goods_baseList: `${base}/back-supplier/goods/certificate-config/baseList`, // 获取基础认证列表
  },
  Tagcontroller: {
    Tagcontroller_SearchTag: `${base}backplat/Tagcontroller/SearchTag`, // 标签查询
    Tagcontroller_AddTag: `${base}backplat/Tagcontroller/AddTag`, // 新增标签
    Tagcontroller_ChangeTagState: `${base}backplat/Tagcontroller/ChangeTagState`, // 改变标签状态
    Tagcontroller_DelTag: `${base}backplat/Tagcontroller/DelTag`, // 删除标签
    Tagcontroller_SearchTagList: `${base}backplat/Tagcontroller/SearchTagList`, // 标签不分页查询
  },
  BrandController: {
    SearchBrand: `${base}backplat/BrandController/SearchBrand`, // 品牌查询
    AddBrand: `${base}backplat/BrandController/AddBrand`, // 新增品牌
    DelBrand: `${base}backplat/BrandController/DelBrand`, // 删除品牌
    EditeBrand: `${base}backplat/BrandController/EditeBrand`, // 编辑品牌
    updateOnOff: `${base}backplat/BrandController/updateOnOff`, // 更新品牌状态
    list: `${base}backplat/BrandController/list`, // 品牌查询
    add: `${base}backplat/BrandController/add`, // 新增品牌
    delete: `${base}backplat/BrandController/delete`, // 删除品牌
    edit: `${base}backplat/BrandController/edit`, // 编辑品牌
    detail: `${base}backplat/BrandController/detail`, // 更新品牌状态
  },
  brand: {
    list: `${base}backplat/brand/list`, // 品牌列表
    add: `${base}backplat/brand/add`, // 新增品牌
    delete: `${base}backplat/brand/delete`, // 删除品牌
    detail: `${base}backplat/brand/detail`, // 品牌详情
    edit: `${base}backplat/brand/edit`, // 编辑品牌
  },
  tradeGoodsComment: {
    page: `${base}backplat/tradeGoodsComment/page`, // 查询商品评价列表
    audit: `${base}backplat/tradeGoodsComment/audit`, // 审核通过/拒绝
    detail: `${base}backplat/tradeGoodsComment/detail`, // 查看商品评价
    remove: `${base}backplat/tradeGoodsComment/remove`, // 删除商品评价
    tradeSensitiveWord_list: `${base}backplat/tradeSensitiveWord/list`, // 敏感词列表
    tradeSensitiveWord_add: `${base}backplat/tradeSensitiveWord/add`, // 添加敏感词
    tradeSensitiveWord_remove: `${base}backplat/tradeSensitiveWord/remove`, // 删除敏感词
    passall: `${base}backplat/tradeGoodsComment/auditAll`, // 通过所有
    hidden: `${base}backplat/tradeGoodsComment/hidden`, // 隐藏评价
  },
  siteArea: {
    list: `${base}backplat/site/list`, // 展馆列表
    listTest: `${base}backplat/site/list/test`, // 展馆列表(三级联动)
    add: `${base}backplat/site/add`, // 展馆创建
    del: `${base}backplat/site/del`, // 删除站点
    all: `${base}backplat/site/all`, // 获取所有站点
    detail: `${base}backplat/site/detail`, // 详情
    edit: `${base}backplat/site/edit`, // 展馆编辑
    opera_status: `${base}backplat/site/opera_status`, // 站点上线/下线
    site_temp: `${base}backplat/site/site_temp`, // 展馆专题模板列表
    siteChild: `${base}/backplat/site/child`, // 获取指定站点下所有站点，0为所有省级馆
    special_site_list: `${base}/backplat/site/special_site_list`, // 特色展馆列表
    specialty_goods_page: `${base}/backplat/specialty_goods/page`, // 展馆平台商品分页列表
    specialty_goods_del: `${base}/backplat/specialty_goods/del`, // 删除展馆平台商品
    specialty_goods_downloadGoodsTemplate: `${base}/backplat/specialty_goods/downloadGoodsTemplate`, // 下载商品导入模板
    specialty_goods_importGoods: `${base}/backplat/specialty_goods/importGoods`, // 导入商品
    specialty_goods_add: `${base}/backplat/specialty_goods/add`, // 特色展馆商品添加校验
    specialty_goods_belonging_specialty: `${base}/backplat/specialty_goods/belonging_specialty`, // 通过商品ID查询所属特色馆
    specialty_store_page: `${base}/backplat/specialty_store/page`, // 展馆平台店铺分页列表
    specialty_store_del: `${base}/backplat/specialty_store/del`, // 删除
    specialty_store_downloadGoodsTemplate: `${base}/backplat/specialty_store/downloadStoreTemplate`, // 下载商
    specialty_store_importGoods: `${base}/backplat/specialty_store/importStore`, // 导入
    specialty_store_add: `${base}/backplat/specialty_store/add`, // 特色展
  },
  goodsmanage_trade_goods: {
    selectCouponGoodsInfo: `${base}backplat/goodsmanage/trade-goods/selectCouponGoodsInfo`, // 优惠券商品列表
  },
  trade_channel_recommend_controller: {
    page: `${base}backplat/tradeChannelRecommendController/page`, // 渠道推荐位分页查询
    add_recommend: `${base}backplat/tradeChannelRecommendController/recommend`, // 新增渠道推荐位
    edit_recommend: `${base}backplat/tradeChannelRecommendController/edit`, // 编辑渠道推荐位
    del_recommend: `${base}/backplat/tradeChannelRecommendController/remove_recommend`, // 删除渠道推荐位
    edit_sort: `${base}backplat/tradeChannelRecommendController/editSort`, // 编辑排序值
    get_recommend_goods_page: `${base}backplat/tradeChannelRecommendController/getRecommendGoodsPage`, // 获取渠道推荐位商品列表
    get_all_goods: `${base}backplat/tradeChannelRecommendController/list`, // 获取渠道下面的所有商品
    remove_good: `${base}backplat/tradeChannelRecommendController/remove`, // ? POST新增推荐---GET取消推荐
    add_good: `${base}/backplat/tradeChannelRecommendController/addGoods`, // 新增推荐商品
    getChannelList: `${base}backplat/tradeChannelRecommendController/getChannelList`, // 运营后台--渠道列表
    selectChannelSupplierInfo: `${base}/backplat/TradeChannelController/selectChannelSupplierInfo`, // 获取渠道商家列表
    selectNotAddSupplierInfo: `${base}/backplat/TradeChannelController/selectNotAddSupplierInfo`, // 获取渠道没有添加的商家列表
    addChannelSupplier: `${base}/backplat/TradeChannelController/addChannelSupplier`, // 添加渠道商家
    deleteChannelSupplier: `${base}/backplat/TradeChannelController/deleteChannelSupplier`, // 删除渠道商家
    getRecommendImagePage: `${base}/backplat/tradeChannelRecommendController/getRecommendImagePage`, // 运营后台--推荐图片分页列表
    addImage: `${base}/backplat/tradeChannelRecommendController/addImage`, // 运营后台--推荐图片新增
    removeImage: `${base}/backplat/tradeChannelRecommendController/removeImage`, // 运营后台--推荐图片删除
    editImage: `${base}/backplat/tradeChannelRecommendController/editImage`, // 运营后台--推荐图片编辑
  },
  tradeChannelController: {
    add: `${base}backplat/tradeChannelController/add`, // 新增应用
    edit_applet: `${base}backplat/tradeChannelController/edit_applet`, // 新增编辑小程序信息
    view_applet: `${base}backplat/tradeChannelController/view_applet`, // 获取新程序信息
    view_personal_setting: `${base}backplat/tradeChannelController/view_personal_setting`, // 获取小程序我的页面信息
    personal_setting_show: `${base}backplat/tradeChannelController/personal_setting_show`, // 更新小程序我的页面信息
  },
  selectGoodsInfo: `${base}backplat/goodsmanage/trade-goods/selectGoodsInfo`, // 查询专题模板组件对应信息
  goods_spec: {
    page: `${base}backplat/spec/page`, // 规格列表
    supplier_customize: `${base}backplat/spec/supplier_customize`, // 商家自定义规格值
    add: `${base}backplat/spec/add`, // 新增规格
    change_status: `${base}backplat/spec/change_status`, // 规格启用禁用
    detail: `${base}backplat/spec/detail`, // 规格详情
    edit: `${base}backplat/spec/edit`, // 编辑规格
    delete: `${base}backplat/spec/delete`, // 删除
    add_spec_value: `${base}back-supplier/spec/add_spec_value`, // 新增规格值
    del_spec_value: `${base}back-supplier/spec/del_spec_value`, // 删除规格值
  },
  goods_spec_group: {
    page: `${base}backplat/spec_group/page`, // 规格组列表
    add: `${base}backplat/spec_group/add`, // 新增规格组
    change_status: `${base}backplat/spec_group/change_status`, // 规格组启用禁用
    delete: `${base}backplat/spec_group/delete`, // 规格组删除
    detail: `${base}backplat/spec_group/detail`, // 规格组详情
    edit: `${base}backplat/spec_group/edit`, // 编辑规格组
  },
  // 商品标签
  goods_supplier_tag: {
    page: `${base}backplat/supplier_tag/page`, // 分页列表
    add: `${base}backplat/supplier_tag/add`, // 新增
    change_status: `${base}backplat/supplier_tag/change_status`, // 商家标签启用禁用
    change_show_status: `${base}backplat/supplier_tag/change_show_status`, // 商家标签显示隐藏
    delete: `${base}backplat/supplier_tag/delete`, // 删除
    detail: `${base}backplat/supplier_tag/detail`, // 详情
    edit: `${base}backplat/supplier_tag/edit`, // 编辑
    export: `${base}backplat/supplier_tag/export`, // 商家标签列表导出
    tagGroupList: `${base}backplat/supplier_tag/tagGroupList`,
    systemPage: `${base}backplat/supplier_tag/system-page`, // 系统标签分页列表
    systemAdd: `${base}backplat/supplier_tag/add-system`, // 新增系统标签
    systemEdit: `${base}backplat/supplier_tag/edit-system`, // 编辑系统标签
    systemTagGoods: `${base}backplat/supplier_tag/system-tag-goods`, // 系统标签商品列表
    systemTagTempDown: `${base}backplat/supplier_tag/downloadGoodsTemplate`, // 下载系统标签商品导入模板
    systemTagImport: `${base}backplat/supplier_tag/import`, // 系统标签商品导入
    systemTagExport: `${base}backplat/supplier_tag/system-tag/export`, // 系统标签商品导出
  },
  // 商品认证
  product_certification: {
    page: `${base}backplat/goods/certificate-config/page`, // 认证列表查询
    getGoodsList: `${base}backplat/goods/certificate-config/getGoodsList`, // 认证商品列表
    add: `${base}backplat/goods/certificate-config/add`, // 新增认证
    edit: `${base}backplat/goods/certificate-config/edit`, // 编辑认证
    detail: `${base}backplat/goods/certificate-config/detail`, // 详情
    del: `${base}backplat/goods/certificate-config/del`, // 删除
    importGoods: `${base}backplat/goods/certificate-config/importGoods`, // 导入认证商品
    addGoods: `${base}backplat/goods/certificate-config/add-certificate-goods`, // 追加商品
    coverGoods: `${base}backplat/goods/certificate-config/cover-certificate-goods`, // 覆盖商品
    exportGoodsList: `${base}backplat/goods/certificate-config/exportGoodsList`, // 导出商品
  },
  // 商品标签组
  goods_supplier_tag_group: {
    page: `${base}backplat/supplier_tag_group/page`, // 分页列表
    add: `${base}backplat/supplier_tag_group/add`, // 新增
    change_status: `${base}backplat/supplier_tag_group/change_status`, // 商家标签启用禁用
    delete: `${base}backplat/supplier_tag_group/delete`, // 删除
    detail: `${base}backplat/supplier_tag_group/detail`, // 详情
    edit: `${base}backplat/supplier_tag_group/edit`, // 编辑
  },
  // 单位管理
  goods_unit: {
    page: `${base}backplat/unit/page`, // 分页列表
    add: `${base}backplat/unit/add`, // 新增
    change_status: `${base}backplat/unit/change_status`, // 商家标签启用禁用
    delete: `${base}backplat/unit/delete`, // 删除
    detail: `${base}backplat/unit/detail`, // 详情
    edit: `${base}backplat/unit/edit`, // 编辑
  },
  // 门店管理
  tradeShop: {
    // list: base + 'back-supplier/trade-shop/list', // 门店列表
    // delete: base + 'back-supplier/trade-shop/delete', // 门店删除
    // edit: base + 'back-supplier/trade-shop/edit', // 门店编辑
    // save: base + 'back-supplier/trade-shop/save' // 门店新增
  },
  // 商品改价记录
  priceLog: {
    getSkuByGoodsId: `${base}backplat/price/log/getSkuByGoodsId/`, // 分页列表
    homestayPriceLogList: `${base}backplat/price/log/homestayPriceLogList`, // 民宿改价列表
    lineChart: `${base}backplat/price/log/lineChart`, // 改价记录-折线图
    priceLogList: `${base}backplat/price/log/priceLogList`, // 农产品改价列表
  },

  // 商品氛围图管理
  goods_atmosphere: {
    add: `${base}backplat/atmosphere/add`, // 新增氛围图
    delete: `${base}backplat/atmosphere/delete`, // 删除氛围图GET
    detail: `${base}backplat/atmosphere/detail`, // 氛围图详情GET
    download: `${base}backplat/atmosphere/download`, // 下载氛围图模板
    edit: `${base}backplat/atmosphere/edit`, // 编辑氛围图
    import: `${base}backplat/atmosphere/import`, // 导入氛围图商品
    list: `${base}backplat/atmosphere/list`, // 氛围图列表
  },

  // 渠道相关
  channel: {
    page: `${base}backplat/private/channel/page`, // 渠道列表查询
    add: `${base}backplat/private/channel/add`, // 专题模板联想查询
    edit: `${base}backplat/private/channel/edit`, // 私域渠道编辑
    del: `${base}backplat/private/channel/del`, // 删除
    off: `${base}backplat/private/channel/close`, // 启停
  },
  // 南浦溪-积分商品
  pointgoods: {
    page: `${base}backplat/pointgoods/page`, // 查询积分商品列表(分页)
    remove: `${base}backplat/pointgoods/remove`, // 删除商品
    modify: `${base}backplat/pointgoods/modify`, // 商品上下架
    detail: `${base}backplat/pointgoods/detail`, // 查看商品详情
    add: `${base}backplat/pointgoods/add`, // 添加积分商品
    edit: `${base}backplat/pointgoods/edit`, // 编辑积分商品
  },
  // 频道页搭建
  component: {
    group_downloadGoodsTemplate: `${base}backplat/component/group/downloadGoodsTemplate`, // 下载商品导入模板
    group_downloadStoreTemplate: `${base}backplat/component/group/downloadStoreTemplate`, // 下载店铺导入模板
    group_importGoods: `${base}backplat/component/group/importGoods`, // 导入商品
    group_importStore: `${base}backplat/component/group/importStore`, // 导入店铺
    group_getItemGroupList: `${base}backplat/component/group/getItemGroupList`, // 商品组/店铺组列表
    group_saveGoods: `${base}backplat/component/group/saveGoods`, // 保存商品
    group_saveStore: `${base}backplat/component/group/saveStore`, // 保存店铺组
    group_delete: `${base}backplat/component/group/delete/`, // 删除分组
    group_updateStatus: `${base}backplat/component/group/updateStatus`, // 暂停更新/恢复更新
    group_detail: `${base}backplat/component/group/detail/`, // 分组详情
    group_getGroupGoodsList: `${base}backplat/component/group/getGroupGoodsList`, // 商品组商品列表
    group_getBlackOrWhiteGoodsList: `${base}backplat/component/group/getBlackOrWhiteGoodsList`, // 黑白名单商品列表
    group_addBlackOrWhiteGoods: `${base}backplat/component/group/addBlackOrWhiteGoods`, // 添加黑白名单商品
    group_removeBlackOrWhiteGoods: `${base}backplat/component/group/removeBlackOrWhiteGoods`, // 移除黑白名单商品
    group_getStoreList: `${base}backplat/component/group/getStoreList`, // 店铺列表
    group_saveCondition: `${base}backplat/component/group/saveCondition`, // 保存条件
    group_updateGoods: `${base}backplat/component/group/updateGoods/`, // 手动更新商品
    group_addItemIds: `${base}backplat/component/group/addItemIds`, // 追加商品/店铺
    group_coverItemIds: `${base}backplat/component/group/coverItemIds`, // 覆盖商品/店铺
    group_saveSortItem: `${base}backplat/component/group/saveSortItem`, // 批量保存商品组内排序
  },
  equity: {
    getGoodEquityList: `${base}backplat/trade/equity/getGoodEquityList`, // 获取权益列表
    addGoodEquity: `${base}backplat/trade/equity/addGoodEquity`, // 添加权益
    deleteGoodEquity: `${base}backplat/trade/equity/deleteGoodEquity`, // 删除权益
    getGoodList: `${base}backplat/trade/equity/getGoodList`, // 搜索权益商品列表
    toAudit: `${base}backplat/trade/equity/toAudit`, // 提交审核
    updateGoodEquity: `${base}backplat/trade/equity/updateGoodEquity`, // 更新权益
  },
  // 采摘卡2.0
  goods_pick: {
    addGoodPickSku: `${base}backplat/pick/addGoodPickSku`, // 添加采摘类目的sku
    updateGoodPickSku: `${base}backplat/pick/updateGoodPickSku`, // 更新采摘类目的sku
    getGoodPickSku: `${base}backplat/pick/getGoodPickSku`, // 查询采摘类目的sku
    getShopInfoByGoodId: `${base}backplat/pick/getShopInfoByGoodId`, // 查询采摘类目的sku
    batchModifyPriceAndStock: `${base}backplat/pick/batchModifyPriceAndStock`, // 批量改价和库存
    batchOpera: `${base}backplat/pick/batchOpera`, // 批量启售、停售
    modifyOnePriceAndStock: `${base}backplat/pick/modifyOnePriceAndStock`, // 指定日期改价和库存

  },
};

export default goodsApis;
