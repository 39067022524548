import Layout from '@/layout/index.vue';

const userRouter = {
  path: '',
  component: Layout,
  meta: { title: '用户管理' },
  children: [
    {
      path: '/user/role/list',
      component: () => import('@/views/nb/user/role/list.vue'),
      meta: {
        title: '角色列表',
      },
    },
    {
      path: '/user/role/detail',
      component: () => import('@/views/nb/user/role/detail.vue'),
      meta: {
        title: '角色详情',
      },
    },
    {
      path: '/user/account/list',
      component: () => import('@/views/nb/user/account/list.vue'),
      meta: {
        title: '账号管理',
      },
    },
    {
      path: '/user/account/detail',
      component: () => import('@/views/nb/user/account/detail.vue'),
      meta: {
        title: '账号详情',
      },
    },
    {
      path: '/user/account/info',
      component: () => import('@/views/nb/user/account/accountInfo.vue'),
      meta: {
        title: '账号信息',
      },
    },
    {
      path: '/user/account/mobile/edit',
      component: () => import('@/views/nb/user/account/mobileEdit.vue'),
      meta: {
        title: '修改手机号',
      },
    },
    {
      path: '/user/permission/list',
      component: () => import('@/views/nb/user/permission/list.vue'),
      meta: {
        title: '账号管理',
      },
    },
    {
      path: '/user/permission/detail',
      component: () => import('@/views/nb/user/permission/infoForm.vue'),
      meta: {
        title: '权限信息',
      },
    },
    {
      path: '/user/list',
      component: () => import('@/views/nb/user/user/list.vue'),
      meta: {
        title: '用户列表',
      },
    },
    {
      path: '/user/anchor',
      component: () => import('@/views/nb/user/user/anchor.vue'),
      meta: {
        title: '个人主播管理',
      },
    },
    {
      path: '/user/detail',
      component: () => import('@/views/nb/user/user/detail.vue'),
      meta: {
        title: '用户信息',
      },
    },

    {
      path: '/user/setting/basic',
      component: () => import('@/views/nb/user/basicSetting/index.vue'),
      meta: {
        title: '基本设置',
      },
    },
    {
      path: '/user/setting/version',
      component: () => import('@/views/nb/user/version/index.vue'),
      meta: {
        title: '版本配置',
        icon: '',
      },
    },
    {
      path: '/suggestlist',
      component: () => import(/* webpackChunkName: "suggestlist" */ '@/views/user/suggestList/index.vue'),
      meta: {
        title: '投诉内容管理',
      },
    },
    {
      path: '/warning/list',
      component: () => import('@/views/nb/user/warning/list.vue'),
      meta: {
        title: '商家预警管理',
      },
    },
    {
      path: '/complaint/list',
      component: () => import('@/views/nb/user/warning/complaintList.vue'),
      meta: {
        title: '用户投诉举报管理',
      },
    },
    {
      path: '/user/blacklistTongdun',
      component: () => import('@/views/nb/user/blacklistTongdun/index.vue'),
      meta: {
        title: '风控黑名单列表',
      },
    },
    {
      path: '/user/memberShipCard',
      component: () => import('@/views/nb/user/memberShipCard/index.vue'),
      meta: {
        title: '会员卡管理',
      },
    },
    {
      path: '/user/pick',
      component: () => import('@/views/nb/user/pick/index.vue'),
      meta: {
        title: '票券核销查询',
      },
    },
  ],
};

export default userRouter;
