import Layout from '@/layout/index.vue';

const merchantsRouter = {
  path: '',
  component: Layout,
  meta: { title: '商家管理' },
  children: [
    {
      path: '/user/merchants/coding',
      component: () => import('@/views/nb/user/merchants/coding.vue'),
      meta: {
        title: '商家赋码',
      },
    },
    {
      path: '/user/merchants/list',
      component: () => import('@/views/nb/user/merchants/merchantsList.vue'),
      meta: {
        title: '商家列表',
      },
    },
    {
      path: '/user/merchants/detail',
      component: () => import('@/views/nb/user/merchants/merchantsDetail.vue'),
      meta: {
        title: '商家详情',
      },
    },
    {
      path: '/user/merchants/addition',
      component: () => import('@/views/nb/user/merchants/merchantAddition'),
      meta: {
        title: '商家详情',
      },
    },
    {
      path: '/user/merchants/audit',
      component: () => import('@/views/nb/user/merchants/merchantsAudit.vue'),
      meta: {
        title: '商家审核',
      },
    },
    {
      path: '/user/merchants/license',
      component: () => import('@/views/nb/user/merchants/merchantsLicense.vue'),
      meta: {
        title: '商家资质',
      },
    },
    {
      path: '/user/merchants/audit/list',
      component: () => import('@/views/nb/user/merchants/merchantsAuditList.vue'),
      meta: {
        title: '商家入驻审核',
        keepAlive: true,
      },
    },
    {
      path: '/user/merchants/platformEntry',
      component: () => import('@/views/nb/user/merchants/platformEntry/index.vue'),
      meta: {
        title: '商家多平台入驻',
        keepAlive: true,
      },
    },
    {
      path: '/supplier/college',
      component: () => import('@/views/nb/user/merchants/university/index.vue'),
      meta: {
        title: '农博大学',
      },
    },
    {
      path: '/supplier/college/detail',
      component: () => import('@/views/nb/user/merchants/university/detail.vue'),
      meta: {
        title: '文章详情',
      },
    },
    {
      path: '/supplier/qa',
      component: () => import('@/views/ldx/qa/index.vue'),
      meta: {
        title: '商家问答',
      },
    },
    {
      path: '/supplier/qaDetails',
      name: 'qaDetails',
      component: () => import('@/views/ldx/qa/details.vue'),
      meta: {
        title: '商家问答详情',
      },
    },
    {
      path: '/user/groupAudit',
      component: () => import('@/views/nb/user/merchants/groupAudit.vue'),
      meta: {
        title: '社区团购审核',
      },
    },
    {
      path: '/user/merchantRecommendation',
      component: () => import('@/views/nb/user/merchants/merchantRecommendation.vue'),
      meta: {
        title: '商家推荐',
      },
    },
  ],
};

export default merchantsRouter;
